import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function Discord() {
  return (
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://drive.google.com/file/d/1DBa5yQmCAnPhcXTWj6mPlodrvfQCENys/view?usp=sharing" />
    </Layout>
  )
}
